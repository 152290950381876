import Image from 'next/image'
import styles from '/styles/components/organisms/lpCarRecommendations.module.scss'

interface TabItemData {
  label: string | JSX.Element
  value: string
  testid?: string
}

export const brandIconList = {
  Toyota: '/revamp/icon/logo-toyota.webp',
  Daihatsu: '/revamp/icon/logo-daihatsu.webp',
  Isuzu: '/revamp/icon/logo-isuzu.webp',
  BMW: '/revamp/icon/logo-bmw.webp',
  Hyundai: '/revamp/icon/logo-hyundai.webp',
  Wuling: '/revamp/icon/logo-wuling.webp',
  BYD: '/revamp/icon/logo-byd.webp',
  Honda: '/revamp/icon/honda.webp',
  Mitsubishi: '/revamp/icon/mitsubishi.webp',
  Suzuki: '/revamp/icon/suzuki.webp',
  Chery: '/revamp/icon/chery-logo-new.webp',
}

export const sizeBrandIcon = {
  Toyota: '21,18',
  Daihatsu: '21.6,15',
  Isuzu: '21.6,7.2',
  BMW: '19.2,19.2',
  Peugeot: '17.49,19.2',
  Honda: '27,14.25',
  Hyundai: '18,18',
  Mitsubishi: '26,15',
  Suzuki: '21,14.25',
  Wuling: '22,12',
  BYD: '21,5',
  Chery: '19.2,19.2',
}

const brandList: TabItemData[] = [
  {
    label: 'Semua',
    value: '',
  },
  {
    label: (
      <div className={styles.tabBrand}>
        <Image
          alt="Toyota Car Recommendation"
          src={brandIconList.Toyota}
          style={{ width: 21, height: 18 }}
          priority
        />
        Toyota
      </div>
    ),
    value: 'Toyota',
  },
  {
    label: (
      <div className={styles.tabBrand}>
        <Image
          alt="Daihatsu Car Recommendation"
          src={brandIconList.Daihatsu}
          style={{ width: 21.6, height: 15 }}
          priority
        />
        Daihatsu
      </div>
    ),
    value: 'Daihatsu',
  },
  {
    label: (
      <div className={styles.tabBrand}>
        <Image
          alt="Isuzu Car Recommendation"
          src={brandIconList.Isuzu}
          style={{ width: 21.6, height: 7.2 }}
          priority
        />
        Isuzu
      </div>
    ),
    value: 'Isuzu',
  },
  {
    label: (
      <div className={styles.tabBrand}>
        <Image
          alt="BMW Car Recommendation"
          src={brandIconList.BMW}
          style={{ width: 19.2, height: 19.2 }}
          priority
        />
        BMW
      </div>
    ),
    value: 'BMW',
  },
  // {
  //   label: (
  //     <div className={styles.tabBrand}>
  //       <Image
  //         alt="Peugeot Car Recommendation"
  //         src={Peugeot}
  //         style={{ width: 17.49, height: 19.2 }}
  //         priority
  //       />
  //       Peugeot
  //     </div>
  //   ),
  //   value: 'Peugeot',
  // },
  {
    label: (
      <div className={styles.tabBrand}>
        <Image
          alt="Hyundai Car Recommendation"
          src={brandIconList.Hyundai}
          style={{ width: 20, height: 20 }}
          width={20}
          height={20}
          priority
        />
        Hyundai
      </div>
    ),
    value: 'Hyundai',
  },
]

export default brandList
